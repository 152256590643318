import { BrowserRouter, Routes, Route } from "react-router-dom";
import DownloadPage from "./pages/DownloadPage";
import Home from "./pages/Home";
import Payment from "./pages/Payment";
import VirtualNin from "./pages/VirtualNin";
import Instructions from "./pages/Instructions";
import Datamodification from "./pages/Datamodification";
import Publication from "./pages/Publication";
import Reprint from "./pages/reprint";
import Terms from "./pages/Terms";
import Privacy from "./pages/privacy";
import Referral from "./pages/Referral";
import Verification from "./pages/Verification";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/virtual_nin" element={<VirtualNin />} />
        <Route exact path="/download" element={<DownloadPage />} />
        <Route exact path="/datamodification" element={<Datamodification />} />
        <Route exact path="/instruction" element={<Instructions />} />
        <Route exact path="/users" element={<Publication />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/reprint" element={<Reprint />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/Verify" element={<Verification />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const NotFoundPage = () => {
  return (
    <div>
      <h1>opps 404 - Page Not Found</h1>
      <p>The requested page was not found.</p>
    </div>
  );
};

export default App;
