import React, { useState } from "react";
import "./style.css";
import logo from "../assests/logo.png";
import logo1 from "../assests/LIC.png";
import { useDispatch } from "react-redux";
import { setData } from "../utils/actions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Home() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [affidavit, setAffidavit] = useState("");
  const [publication, setPublication] = useState("");
  const [persons, setPersons] = useState("");
  const [others, setOthers] = useState("");
  const [name, setName] = useState("");
  const [newName, setNewName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [referral, setReferrals] = useState("");

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setImageUrl(file);

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const dataUrl = reader.result;
        // dataUrl is the base64-encoded string representation of the file
        setAffidavit(dataUrl);
      };
    }
  };

  const handleOptionSelect = (event) => {
    setPublication(event.target.value);
  };
  const handleOptionSelectTwo = (event) => {
    setPersons(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    Promise.resolve(
      dispatch(
        setData(
          name,
          newName,
          affidavit,
          publication,
          persons,
          others,
          referral
        )
      )
    ).then(() => {
      // Redirect to payment page
      navigate("/payment");
    });
  };

  return (
    <div className="container">
      <div className="nav-container">
        <div className="nav">
          <div className="nav-image">
            <div className="logo">
              <Link to="/">
                <img src={logo} style={{ height: "80px" }} alt="logo" />
              </Link>
            </div>
          </div>
          <Link to="/reprint" className="reprint">
            Reprint
          </Link>{" "}
          <Link to="/Verify" className="verify">
            Verify
          </Link>{" "}
        </div>
      </div>
      <div className="marquee">
        <div>
          Caution!!! This service is for the purpose of NEwspaper publication
          only and does not in any way seek to modify, update or alter your
          already existing information of your NIN in the National identity
          Management Commision(NIMC) database
        </div>
      </div>
      <div className="publish-container">
        <div className="publish">
          <div className="publish-section-header">
            <i
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                opacity: "1",
                letterSpacing: "1px",
                fontFamily: "lato",
              }}
            >
              Good news!
              <br />
              You can now link your Change of Name newspaper publication online
              with us and link it with your NIN
              <br />
              You will get:
            </i>
            <div className="publish-content">
              <i className="fa-solid fa-forward"></i>
              <p>Publication Certificate Immediately</p>
            </div>
            <div className="publish-content">
              <i className="fa-solid fa-forward"></i>
              <p>NIN Linked Publication Certificate</p>
            </div>
            <div className="publish-content">
              <i className="fa-solid fa-forward"></i>
              <p>
                Certificate Verifiable by any Organization School, embassies,
                MDA's etc.
              </p>
            </div>
            <div className="publish-content">
              <i className="fa-solid fa-forward"></i>
              <p>National Newspaper Placement.</p>
            </div>
          </div>
          <div className="publish-section-footer">
            <p>Approved by NIMC</p>
            <img src={logo1} alt="logo" />
          </div>
        </div>
        <div className="publish-form">
          <form className="form-box" onSubmit={handleSubmit}>
            <p>
              Input Old Name<span className="form-box-asterix">*</span>
            </p>
            <input
              className="form-box-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <p>
              New Name<span className="form-box-asterix">*</span>
            </p>
            <input
              className="form-box-input"
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              required
            />
            <p>
              Upload your Affidavit/ marriage certificate or other documents
            </p>
            <label>
              <input
                type="file"
                accept="image/*" // Specify the accepted file types to be images only
                onChange={handleFileSelect}
                style={{ display: "none" }}
                required
              />
              <i className="fa-solid fa-file"></i>
              {imageUrl && (
                <p
                  className="file-input"
                  style={{ marginTop: "0.4rem", fontSize: "14px" }}
                >
                  {imageUrl.name}
                </p>
              )}
            </label>
            <small>File size not exceeding 5mb, supports jpeg, png, pdf</small>
            <p>
              Choose your Publication type
              <span className="form-box-asterix">*</span>
            </p>
            <select
              value={publication}
              onChange={handleOptionSelect}
              className="my-select"
              required
            >
              <option value=""></option>
              <option value="4800">
                {" "}
                Change of Name
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="form-box-amount">N4,800</span>{" "}
              </option>
              <option value="4750">
                Data Modification publication for NIMC
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="form-box-amount">N4,750</span>
              </option>
            </select>
            <p>
              General public to be notified{" "}
              <span className="form-box-asterix">*</span>
            </p>
            <select
              value={persons}
              onChange={handleOptionSelectTwo}
              className="my-select"
              required
            >
              <option value=""></option>
              <option value="General Public">General Public</option>
            </select>
            <p>
              Persons or Organization to be notified{" "}
              <span className="form-box-asterix">*</span>
            </p>
            <input
              className="form-box-input"
              type="text"
              value={others}
              onChange={(e) => setOthers(e.target.value)}
              required
            />
            <small>Please limit your message to 5 words or less.</small>
            <p>
              Referral Code (optional)
              <span className="form-box-asterix">*</span>
            </p>
            <input
              className="form-box-input"
              type="text"
              value={referral}
              onChange={(e) => setReferrals(e.target.value)}
              required
            />
            <button type="submit">Continue</button>
          </form>
        </div>
      </div>

      <div className="footer-container">
        <div className="footer">
          <div className="footer-header">
            <h2>
              {" "}
              <Link to="/referral">&copy; </Link>
              <br /> The Classified Newspaper 2023{" "}
            </h2>
          </div>
          <div className="footer-content">
            <Link to="/terms">Terms and Condition</Link>
            <a href="#">About Us</a>
            <Link to="/privacy">Privacy Policy</Link>
            <div className="nav-download1">
              <a href="">Contact us</a>
              <p>09090909137 </p>
              <p>09090909136 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
