import React, { useState, useEffect } from "react";
import "./styling.css";
import LoadingSpinner from "./Loadingspinner";

const Referral = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch("https://nimc.onrender.com/api/getalldata/")
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="table-container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Referral Code</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item, index) => (
              <tr key={index}>
                <td>{item.newName}</td>
                <td>{item.referral}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Referral;
