import React from "react";
import { Link } from "react-router-dom";
import logo from "../assests/logo.png";
import "./style.css";

function Privacy() {
  return (
    <>
      <div className="instructions-container " style={{ textAlign: "center" }}>
        <div className="nav-container">
          <div className="nav">
            <div className="nav-image">
              <div className="logo">
                <Link to="/">
                  <img src={logo} style={{ height: "80px" }} alt="logo" />
                </Link>
              </div>
            </div>
            <a href="/reprint" className="reprint">
              Reprint
            </a>{" "}
          </div>
        </div>
        <div className="marquee">
          <div>
            Caution!!! This service is for the purpose of NEwspaper publication
            only and does not in any way seek to modify, update or alter your
            already existing information of your NIN in the National identity
            Management Commision(NIMC) database
          </div>
        </div>
        <div className="terms" style={{ textAlign: "center" }}>
          <h2>Definitions</h2>
          <p>
            <strong>SERVICE</strong> means the{" "}
            <a href="https://changeyourname.ng">https://changeyourname.ng</a>{" "}
            website operated by Classified and Such Communications Limited.
          </p>
          <p>
            <strong>PERSONAL DATA</strong> means data about a living individual
            who can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </p>
          <p>
            <strong>USAGE DATA</strong> is data collected automatically either
            generated by the use of Service or from Service infrastructure
            itself (for example, the duration of a page visit).
          </p>
          <p>
            <strong>COOKIES</strong> are small files stored on your device
            (computer or mobile device).
          </p>
          <p>
            <strong>DATA CONTROLLER</strong> means a natural or legal person who
            (either alone or jointly or in common with other persons) determines
            the purposes for which and the manner in which any personal data
            are, or are to be, processed. For the purpose of this Privacy
            Policy, we are a Data Controller of your data.
          </p>
          <p>
            <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any
            natural or legal person who processes the data on behalf of the Data
            Controller.
          </p>
          <h3>Information Collection and Use</h3>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h4>Types of Data Collected</h4>
          <p>
            <strong>Personal Data</strong>
          </p>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>0.1. Email address</li>
            <li>0.2. First name and last name</li>
            <li>0.3. Phone number</li>
            <li>
              0.4. Address, Country, State, Province, ZIP/Postal code, City
            </li>
            <li>0.5. Cookies and Usage Data</li>
          </ul>
          <p>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials, and other information that may
            be of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>

          <p>
            <strong>Usage Data</strong>
          </p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any
            device ("Usage Data").
          </p>
          <p>
            <strong>Location Data</strong>
          </p>
          <p>
            We may use and store information about your location if you give us
            permission to do so ("Location Data"). We use this data to provide
            features of our Service, to improve and customize our Service. You
            can enable or disable location services when you use our Service at
            any time by way of your device settings.
          </p>

          <p>
            <strong>Tracking Cookies Data</strong>
          </p>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags, and scripts to collect and track
            information and to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>
            <strong>Examples of Cookies we use:</strong>
          </p>
          <ul>
            <li>
              0.1. Session Cookies: We use Session Cookies to operate our
              Service.
            </li>
            <li>
              0.2. Preference Cookies: We use Preference Cookies to remember
              your preferences and various settings.
            </li>
            <li>
              0.3. Security Cookies: We use Security Cookies for security
              purposes.
            </li>
            <li>
              0.4. Advertising Cookies: Advertising Cookies are used to serve
              you with advertisements that may be relevant to you and your
              interests.
            </li>
          </ul>

          <p>
            <strong>Other Data</strong>
          </p>
          <p>
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements, information on bonuses and compensation, information on
            marital status, family members, social security (or other taxpayer
            identification) number, office location, and other data.
          </p>

          <h4>Use of Data</h4>
          <p>
            Classified and Such Communications Limited uses the collected data
            for various purposes:
          </p>
          <ul>
            <li>0.1. to provide and maintain our Service;</li>
            <li>0.2. to notify you about changes to our Service;</li>
            <li>
              0.3. to allow you to participate in interactive features of our
              Service when you choose to do so;
            </li>
            <li>0.4. to provide customer support;</li>
            <li>
              0.5. to gather analysis or valuable information so that we can
              improve our Service;
            </li>
            <li>0.6. to monitor the usage of our Service;</li>
            <li>0.7. to detect, prevent and address technical issues;</li>
            <li>0.8. to fulfill any other purpose for which you provide it;</li>
            <li>
              0.9. to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;
            </li>
          </ul>
          <h4>6. Retention of Data</h4>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>

          <h4>7. Transfer of Data</h4>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country, or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Classified and Such Communications Limited will take all the steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Privacy Policy, and no transfer of your
            Personal Data will take place to an organization or a country unless
            there are adequate controls in place, including the security of your
            data and other personal information.
          </p>

          <h4>8. Disclosure of Data</h4>
          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <ul>
            <li>
              0.1. Business Transaction: If we or our subsidiaries are involved
              in a merger, acquisition, or asset sale, your Personal Data may be
              transferred.
            </li>
            <li>0.2. Other cases: We may disclose your information also:</li>
            <ul>
              <li>0.2.1. to our subsidiaries and affiliates;</li>
              <li>
                0.2.2. to contractors, service providers, and other third
                parties we use to support our business;
              </li>
              <li>0.2.3. to fulfill the purpose for which you provide it;</li>
              <li>
                0.2.4. for the purpose of including your company's logo on our
                website;
              </li>
              <li>
                0.2.5. for any other purpose disclosed by us when you provide
                the information;
              </li>
              <li>0.2.6. with your consent in any other cases;</li>
              <li>
                0.2.7. if we believe disclosure is necessary or appropriate to
                protect the rights, property, or safety of the Company, our
                customers, or others.
              </li>
            </ul>
          </ul>

          <h4>9. Security of Data</h4>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h4>
            10. Your Data Protection Rights Under General Data Protection
            Regulation (GDPR)
          </h4>
          <p>
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR. We aim to take reasonable steps to allow you to
            correct, amend, delete, or limit the use of your Personal Data.
          </p>
          <h4>19. If you wish to be informed</h4>
          <p>
            If you wish to be informed about what Personal Data we hold about
            you and if you want it to be removed from our systems, please
            contact us at:
          </p>
          <p>
            The Classified Newspaper,
            <br />
            Classified and Such Communications Limited,
            <br />
            Plot 746, AMAC Estate, Airport Road, Sabonlugbe, Abuja, 09090909137
          </p>
          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ul>
            <li>
              0.1. the right to access, update or to delete the information we
              have on you;
            </li>
            <li>
              0.2. the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li>
              0.3. the right to object. You have the right to object to our
              processing of your Personal Data;
            </li>
            <li>
              0.4. the right of restriction. You have the right to request that
              we restrict the processing of your personal information;
            </li>
            <li>
              0.5. the right to data portability. You have the right to be
              provided with a copy of your Personal Data in a structured,
              machine-readable, and commonly used format;
            </li>
            <li>
              0.6. the right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </li>
          </ul>
          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>

          <h4>11. Service Providers</h4>
          <p>
            We may employ third-party companies and individuals to facilitate
            our Service ("Service Providers"), provide Service on our behalf,
            perform Service-related services, or assist us in analyzing how our
            Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <h4>12. Analytics</h4>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <h4>13. CI/CD tools</h4>
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>

          <h4>14. Behavioral Remarketing</h4>
          <p>
            We may use remarketing services to advertise on third-party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimize, and serve ads based on your past
            visits to our Service.
          </p>

          <h4>15. Payments</h4>
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express, and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>

          <h4>16. Links to Other Sites</h4>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third-party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or
            services.
          </p>

          <h4>17. Children's Privacy</h4>
          <p>
            Our Services also collect information from children under the age of
            18 ("Child" or "Children") for their NIN with the Federal Government
            of Nigeria but the parental or guidance consent.
          </p>

          <h4>18. Changes to This Privacy Policy</h4>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h4>19. Contact Us</h4>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p>
            The Classified Newspaper,
            <br />
            Classified and Such Communications Limited,
            <br />
            Plot 746, AMAC Estate, Airport Road, Sabonlugbe, Abuja, 09090909137
          </p>
        </div>
        <div className="footer-container">
          <div className="footer">
            <div className="footer-header">
              <h2>
                {" "}
                &copy; 2023 <br /> The Classified Newspaper{" "}
              </h2>
            </div>
            <div className="footer-content">
              <Link to="/terms">Terms and Condition</Link>
              <a href="#">About Us</a>
              <Link to="/privacy">Privacy Policy</Link>
              <div className="nav-download1">
                <a href="">Contact us</a>
                <p>09090909137 </p>
                <p>09090909136 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
