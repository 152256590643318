import React, { useState, useEffect } from "react";
import "./style.css";
import "./instructions.css";
import logo from "../assests/logo.png";
import { Link } from "react-router-dom";
import LoadingSpinner from "./Loadingspinner";

export default function Verification() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(2);
  const itemsPerPage = 10;

  useEffect(() => {
    fetch("https://nimc.onrender.com/api/getalldata/")
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setIsLoading(false);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset the current page to 1 when a user starts a new search
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.includes(searchTerm) || user.newName.includes(searchTerm)
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  if (isLoading) {
    return (
      <div>
        {" "}
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="nav-container">
          <div className="nav">
            <div className="nav-image">
              <div className="logo">
                <Link to="/">
                  <img
                    src={logo}
                    style={{ height: "80px", width: "70vw" }}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="search">
          <div className="search-container">
            <h1>Verification Section</h1>

            <label style={{ fontSize: "22px", color: "red" }}>
              Input your name:
            </label>
            <input
              type="text"
              placeholder="Name"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-box-input2"
            />
          </div>
        </div>
        {searchTerm ? (
          <div className="user-grid">
            {currentItems.map((user) => (
              <>
                {" "}
                <table style={{ marginTop: "5vh", marginBottom: "60vh" }}>
                  <tr>
                    <th>former Name</th>
                    <th>New Name</th>
                    <th>Status</th>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "22px", color: "red" }}>
                      {user.name}
                    </td>
                    <td style={{ fontSize: "22px", color: "red" }}>
                      {user.newName}
                    </td>
                    <td style={{ fontSize: "22px", color: "red" }}>
                      Verified✅
                    </td>
                  </tr>
                  <h3>
                    The verification exercise was successfully completed🎉🎉🎉
                  </h3>
                </table>
              </>
            ))}
          </div>
        ) : (
          <div
            style={{
              height: "60vh",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <h1>Not yet Verified</h1>
          </div>
        )}

        <div className="footer-container">
          <div className="footer">
            <div className="footer-header">
              <h2>
                {" "}
                &copy; 2023 <br /> The Classified Newspaper{" "}
              </h2>
            </div>
            <div className="footer-content">
              <Link to="/terms">Terms and Condition</Link>
              <a href="#">About Us</a>
              <Link to="/privacy">Privacy Policy</Link>
              <div className="nav-download1">
                <a href="">Contact us</a>
                <p>09090909137 </p>
                <p>09090909136 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
