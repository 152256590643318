import React from "react";
import { Link } from "react-router-dom";
import logo from "../assests/logo.png";
import "./style.css";

function Terms() {
  return (
    <>
      <div className="container">
        <div className="nav-container">
          <div className="nav">
            <div className="nav-image">
              <div className="logo">
                <Link to="/">
                  <img src={logo} style={{ height: "80px" }} alt="logo" />
                </Link>
              </div>
            </div>
            <a href="/reprint" className="reprint">
              Reprint
            </a>{" "}
          </div>
        </div>
        <div className="marquee">
          <div>
            Caution!!! This service is for the purpose of NEwspaper publication
            only and does not in any way seek to modify, update or alter your
            already existing information of your NIN in the National identity
            Management Commision(NIMC) database
          </div>
        </div>
        <div className="terms">
          <h1>Terms and Conditions</h1>
          <h2>DEFINITION</h2>
          <p>
            The Classified Newspaper application’s (referred to as
            ‘www.changeyourname.ng’), is an online, seamless and real-time
            application herein referred to as ‘‘Platform’’ that provides
            Newspaper Publication services for Name change, Name rearrangement,
            Data modification Publication and Virtual National Identity Number
            verification hereinafter referred to as VNIN and Persons identity
            verification services.
          </p>

          <h2>Consent</h2>
          <p>
            This Terms and conditions of Use hereby constitutes a legally
            binding contract between the user (whether as a guest or registered
            user) and us in the accessing and usage of this Platform as well as
            its content, services and functionality made available on or through
            the application. We therefore kindly request that you carefully read
            through before using the Platform
          </p>

          <p>
            By using the Platform, you accept the terms and conditions contained
            on this Platform, which shall govern your use of this Platform,
            including all pages or links on the Platform. If you do not agree to
            these Terms of Use or our Privacy Policy, please do not use the
            Platform and/or the Services and exit immediately. In addition, if
            you do not agree to these Terms and Conditions or our Privacy
            Policy, you agree that your sole and exclusive remedy is to
            discontinue using this Platform. That notwithstanding, if you
            proceed to browsing, accessing, or otherwise using the Services in
            any manner, it will be deemed that you have accepted, without
            limitation or qualification, both the Terms and Conditions and our
            Privacy Policy for this service (the "Policy").
          </p>

          <h2>Review of Terms of Use</h2>
          <p>
            We may, at our sole discretion, review, revise and/or update this
            Terms and Conditions on the Platform at any time. We therefore
            advise that you read through this Terms and Conditions periodically.
            You further agree that we will have no further obligation to notify
            you of any modifications and such updates or modifications shall
            become effective immediately upon the posting thereof or on any
            specified date. The most current version of the Terms and Conditions
            can be accessed at any time by selecting Terms and Conditions link
            found on our website.
          </p>

          <h2>Age</h2>
          <p>
            User must be at least 18 (eighteen) years old to use our Platform or
            any of our Services; by using our Platform or agreeing to these
            terms and conditions, you represent and warrant to us that you are
            at least 18 years of age. Individuals under the age of 18, or
            applicable age of maturity, may utilize our Services only with
            involvement of a parent or legal guardian, under such person’s
            account.
          </p>

          <h2>User Information/Know- Your-Customer (KYC) Requirements</h2>
          <p>
            In creating an account and registering on our Platform, we may
            require you to provide and/or confirm information and documentation
            that will allow us to identify you, such as:
          </p>

          <ul>
            <li>
              A copy of your certificate of incorporation (for organizations) or
              government-issued photo ID, such as a passport, driver’s license
              or other valid means of identification;
            </li>
            <li>
              Such other information and documentation that we may require from
              time to time.
            </li>
          </ul>

          <p>
            You hereby authorize us to, directly or through a third-party,
            obtain, verify, and record information and documentation that helps
            us verify your identity and other relevant information.
          </p>
          <h2>Service Terms</h2>
          <p>
            The Platform allows users to request for Services. Accordingly, by
            using our Platform, you agree to be bound by the following terms:
          </p>
          <p>
            As a User of this Platform, you are responsible for making your own
            decision based on your independent enquiries, appraisals, judgment,
            wisdom and risks. The Classified Newspaper, its affiliates,
            employees, directors or agents shall not be liable for any damage,
            direct or indirect or loss or costs incurred due to the use of or
            relying upon any information, report, verification or contents of
            this site or any website that maybe linked to this Platform.
          </p>
          <p>
            As a User of our Platform, you are only granted a limited,
            non-exclusive, non-transferable, non-sub-leasable license to access
            and make personal and non-commercial use of the Platform. All rights
            not expressly granted to you in these Terms and Conditions or any
            specific Service Level Agreement are reserved and retained by The
            Classified Newspaper.
          </p>
          <p>
            Permission is granted to temporarily download one copy of the
            publication certificate and Newspaper Publication (information or
            software) on the Platform for personal, non-commercial transitory
            viewing only after which charges will apply after 30 days of first
            initial download. This is the grant of a license, not a transfer of
            title, and under this license you may not:
          </p>
          <ul>
            <li>Modify or copy the materials;</li>
            <li>
              Use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              Attempt to decompile or reverse engineer any software contained on
              The Classified Newspaper website or Platforms;
            </li>
            <li>
              Remove any copyright or other proprietary notations from the
              materials.
            </li>
            <li>
              Transfer the materials to another person or "mirror" the materials
              on any other server.
            </li>
          </ul>
          <p>
            This license shall automatically terminate if you violate any of
            these restrictions and in any event, may be terminated by The
            Classified Newspaper at any time. Upon terminating your viewing of
            these materials or upon the termination of this license, you must
            destroy any downloaded materials in your possession whether in
            electronic or printed format.
          </p>
          <h3>Available Content and Use</h3>
          <p>Content Description:</p>
          <p>
            The Platform contains variety of Contents (which includes but not
            limited to text, data, information, files, documents, software,
            scripts, layout, design, function, aesthetics, graphics, images,
            audio, videos, audiovisual combinations, interactive features and
            any other materials) that you may view, access or download (but only
            as expressly permitted herein) on the Platform.
          </p>
          <h3>Restrictions on the use of the Platform</h3>
          <p>
            Please note that the Contents of this Platform are solely for your
            information and use, as intended through the provided functionality
            of the Services and permitted under this Terms and Conditions. As
            such, you:
          </p>
          <ul>
            <li>
              shall not use our Platform in any way or take any action that
              causes or may cause damage to the Platform or The Classified
              Newspaper, or impair the performance, availability or
              accessibility of the application;
            </li>
            <li>
              shall not use our application in any way that is fraudulent or
              harmful, unlawful, illegal, or in connection with any unlawful,
              illegal, fraudulent or harmful purpose or activity or in any way
              that breaches any applicable law or regulations, including the
              Nigeria Data Protection Regulations 2019;
            </li>
            <li>
              shall not to circumvent, disable or otherwise interfere with
              security-related features of the Services, including security
              features that prevent or restrict the use or copying of any
              content;
            </li>
            <li>
              shall not use data collected from our application website for any
              direct marketing activity (including without limitation email
              marketing, SMS marketing, telemarketing or direct mailing);
            </li>
            <li>
              shall not to alter, remove, or falsify any Content, attributions
              or other proprietary designations of origin or source of any
              Content appearing on the website;
            </li>
            <li>
              shall not use the Platform in any way to create liability, or in
              any way that causes us to lose the services of our Internet
              Service Providers or expose us to regulatory sanction;
            </li>
            <li>
              shall not download any Content on the Platform unless you see a
              "download" or similar link displayed by us on the Platform for
              such content, or unless we permit you to do so.
            </li>
          </ul>
          <h3>Disclaimer</h3>
          <p>
            Use of the Services is at your sole risk and the Platform is
            provided to you on an 'AS IS' and 'AS AVAILABLE' basis. You
            therefore agree to evaluate, seek independent advice and bear all
            risks associated with the use of our Platform.
          </p>
          <p>
            We will not be liable for any damages (including, without
            limitation, damages for any consequential loss) howsoever arising
            and whether in contract, tort or otherwise from the use of or
            inability to use our Platform, or any of its contents and materials,
            or from any action or omission taken as a result of using the
            Platform or any such contents.
          </p>
          <p>
            All liabilities are excluded to the fullest extent permitted by
            applicable law including any implied terms, as the Contents of this
            Platforms are provided "as is" without warranties of any kind. We
            reserve the right to change, revise or update any or the Contents of
            our Platform at any time without notice to you.
          </p>
          <p>You undertake that:</p>
          <ul>
            <li>
              you are responsible for your own conduct while using the Platform
              or Services and for any consequences thereof
            </li>
            <li>
              you shall use this Platform and all Services on the Platform only
              for change of name publication linked with your VNIN purposes and
              purposes that are legal, proper and in accordance with this Terms
              and Conditions, the Privacy Policy, and any applicable law, rules
              or regulations, any consumer protection, unfair competition, and
              anti-discrimination laws or regulations and any applicable foreign
              laws.
            </li>
            <li>
              You are solely responsible for your interactions with other users
              of the Platform ("Users") both online and outside of the Platform.
              The Classified Newspaper expressly disclaims any responsibility
              for interactions, loss or liabilities between users or between you
              and any third party outside of the Platform.
            </li>
          </ul>
          <h3>Confidentiality</h3>
          <p>
            User undertake that all communication, content, verification
            reports, intellectual property or other information, personal data
            and materials on this Platform, either marked 'confidential' or is
            by its nature intended to be for your knowledge alone, shall be kept
            confidential unless or until you can reasonably demonstrate that
            such communication, information and material is, or part of it is,
            in the public domain through no fault of yours. Furthermore, any
            communication, content, intellectual property or other information,
            and materials you obtain in terms of or arising from the use of our
            Platform shall be treated as confidential and shall not be divulged
            or permitted to be divulged to third parties, without our prior
            written consent.
          </p>
          <p>
            The confidentiality obligation in above shall not apply to
            information which:
          </p>
          <ul>
            <li>
              was in your possession or independently available to you without
              restriction before receipt from The Classified Newspaper; or
            </li>
            <li>
              was a matter of public knowledge except through your own fault; or
            </li>
            <li>
              is rightfully received from a third party without a duty of
              confidentiality; or
            </li>
            <li>
              is independently developed without using any of The Classified
              Newspaper confidential information, as evidenced by
              contemporaneous documentation; or
            </li>
            <li>
              is disclosed, with The Classified Newspaper prior written
              approval; or
            </li>
            <li>
              is demanded by a governmental, judicial or regulatory order,
              provided that prior to making such disclosures, written
              notification of the demand shall first be given to The Classified
              Newspaper.
            </li>
          </ul>
          <p>
            Please note that all obligations relating to Confidential
            Information under this Terms and Conditions will continue after
            termination of the Services or your use of our Platform, or
            termination of your access rights.
          </p>
          <h3>Use of Suggestions</h3>
          <p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information provided by you to The
            Classified Newspaper (collectively, "Feedback") unless expressly
            stated to be confidential, are not confidential and you hereby grant
            to The Classified Newspaper a worldwide, perpetual, irrevocable,
            royalty-free license to reproduce, implement, display, perform,
            distribute, publish, modify, edit or otherwise use such Feedback as
            it deems appropriate, for any and all commercial or non-commercial
            purposes, in its sole discretion.
          </p>
          <h3>Undertakings and Warranties</h3>
          <p>
            You acknowledge and agree that The Classified Newspaper has
            permitted the use of our Platform in full reliance on the
            representations, warranties, undertakings and agreements under this
            Terms of Use.
          </p>

          <p>
            You warrant that your use of our Platform is for a lawful purpose,
            and is based on at least one of the following conditions:
          </p>
          <ul>
            <li>
              you have the consent of the identifiable person ("data subject"),
              whose information you intend to verify;
            </li>
            <li>
              it is necessary for the performance of a contract to which the
              data subject is party or in order to take steps at the request of
              the data subject prior to entering into a contract;
            </li>
            <li>
              it is necessary for compliance with a legal obligation to which
              you are subject;
            </li>
            <li>
              it is necessary in order to protect the vital interests of the
              data subject or of another natural person; or
            </li>
            <li>
              it is necessary for the performance of a task carried out in the
              public interest or in exercise of official public mandate vested
              in you.
            </li>
          </ul>

          <p>You hereby agree as follows:</p>
          <ul>
            <li>
              not to access or use the Services in an unlawful way or for an
              unlawful or illegitimate purpose, including without limitation any
              violation of any data protection laws or other applicable laws of
              the Federal Republic of Nigeria or any other jurisdiction where
              you are resident;
            </li>
            <li>
              not to post, publish or transmit on the Platform:
              <ol type="a">
                <li>any message or information under a false name;</li>
                <li>
                  information that is unlawful, malicious, libelous, defamatory,
                  obscene, fraudulent, predatory of minors, harassing,
                  discriminatory, threatening or hateful to any person; or
                </li>
                <li>
                  information that infringes or violates any contractual or
                  intellectual property rights of others or the privacy or
                  publicity rights of others;
                </li>
              </ol>
            </li>
            <li>
              not to post, process, publish or transmit any content (including
              works, data, materials (including without limitation), text,
              graphics, images, information, audio material, audio-visual
              material, scripts, software and files) that is illegal or
              unlawful, infringe any person’s legal rights, or is capable of
              giving rise to legal action against us or any person (in any
              jurisdiction and under any applicable law).
            </li>
            <li>
              not to transmit, distribute, introduce or otherwise make available
              in any manner through the Services any computer virus, keyloggers,
              malware, spyware, worms, Trojan horses, time bombs or other
              malicious or harmful code (collectively, "Harmful Code") or to do
              any act capable of disrupting the operation of the Platform.
              Please note that we do not have an obligation to detect the
              presence of any Harmful Code. Please note that if you download any
              Content from the Platform, you do so at your own risk.
            </li>
            <li>
              not to use the Platform in any manner that could damage, disable
              or impair our services or networks;
            </li>
            <li>
              not to attempt to gain unauthorized access to the Platform or any
              user accounts or computer systems or networks, through hacking,
              password mining or any other unlawful means.
            </li>
          </ul>
          <h3>Intellectual Property</h3>
          <p>
            The Classified Newspaper shall remain the owner of the know-how,
            trademarks, service marks, logos, slogans, patents, copyright or
            other intellectual property rights belonging to it within or outside
            the Platform. The use of the Platform, and nothing contained herein
            shall not license nor transfer any intellectual property right
            belonging to The Classified Newspaper, and you are not allowed to
            use any such rights belonging to The Classified Newspaper without
            our written consent.
          </p>

          <p>
            If you believe that any content on the Platform infringes upon your
            intellectual property or privacy rights, please contact us through
            the contact details below:
          </p>
          <p>
            The Classified Newspaper, Classified and Such Communications
            Limited, Plot 746, AMAC Estate, Airport Road, Sabonlugbe, Abuja,
            09090909137
          </p>

          <h3>No Refunds</h3>
          <p>
            The Classified Newspaper operates a no refund policy regarding
            completed Services. We shall not refund you for any payment made for
            completed transactions notwithstanding the date, time and method of
            payment adopted. In the event that The Classified Newspaper
            determines that you are entitled to a refund of any part of the fees
            you paid in respect of our Services, such refund shall only be made
            toward future fees for use of our service and are not convertible
            into cash or any other type of monetary refund.
          </p>

          <h3>Financial Partners</h3>
          <p>
            We partner with other financial/payment service providers in
            providing our Services to you. In order to enable you to make
            payments on/through the Platform, we may share your information with
            these financial partners. You therefore authorize us to share your
            identity and banking information with partner financial institutions
            in respect of your payments for our Services. The Classified
            Newspaper shall not be liable for any default, negligence or breach
            by the financial partners.
          </p>
          <h3>Termination/Suspension</h3>
          <p>
            In the event of any breach of this Terms of Use, The Classified
            Newspaper shall have the right to suspend or terminate all or a
            portion of the Services to you at our discretion, or to restrict
            your access to the Platform. We reserve the right to revoke,
            terminate, or suspend any privileges associated with accessing the
            Services for any legitimate reason (including regulatory
            instruction). You agree that The Classified Newspaper shall not be
            liable to you or any third party for any termination or suspension
            of your access to the Platform.
          </p>

          <p>
            If you wish to close your account, you may do so by contacting The
            Classified Newspaper’s customer service through the contact details
            below. Unless otherwise specified and agreed, you shall remain
            obligated to settle all outstanding payments, if any, relating to
            your use of the Platform prior to such closure request:
          </p>
          <p>
            The Classified Newspaper, Classified and Such Communications
            Limited, Plot 746, AMAC Estate, Airport Road, Sabonlugbe, Abuja,
            09090909137
          </p>
        </div>
        <div className="footer-container">
          <div className="footer">
            <div className="footer-header">
              <h2>
                {" "}
                &copy; 2023 <br /> The Classified Newspaper{" "}
              </h2>
            </div>
            <div className="footer-content">
              <Link to="/terms">Terms and Condition</Link>
              <a href="#">About Us</a>
              <Link to="/privacy">Privacy Policy</Link>
              <div className="nav-download1">
                <a href="">Contact us</a>
                <p>09090909137 </p>
                <p>09090909136 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
