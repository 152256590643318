import { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { useSelector } from "react-redux";
import "./payment.css";
import logo from "../assests/logo.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "./Loadingspinner";
import { Link } from "react-router-dom";

function Payment() {
  const { name, newName, affidavit, publication, persons, referral } =
    useSelector((state) => state.mainReducer);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect back to home page if transactionId is empty string
    if (name === "") {
      navigate("/");
    }
  });
  // const[phone, setPhone]= useState('')
  // pk_live_d92d0276c0b911921fb28020c94e9e812ca6fe1b

  const publicKey = "pk_live_d92d0276c0b911921fb28020c94e9e812ca6fe1b";
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [paystackName, setPaystackName] = useState("");
  let numberChange = parseInt(publication);
  const vat = 0;
  const totalAmount = numberChange + vat;
  const payStackAmount = parseInt(totalAmount);
  const koboAmount = payStackAmount * 100;
  const amount = koboAmount + 0;

  const componentProps = {
    email,
    amount,
    metadata: {
      paystackName,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: (transaction) => {
      const { reference } = transaction;
      const transactionId = reference;

      if (
        transactionId === "" ||
        transactionId === undefined ||
        transactionId === null
      ) {
        toast("transaction unsuccessfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast("transaction successfull");
      }
      axios
        .post("https://nimc.onrender.com/api/adddata/", {
          name,
          newName,
          affidavit,
          publication,
          persons,
          referral,
          transactionId,
        })
        .then((response) => {
          navigate("/virtual_nin");
        })
        .catch((error) => {
          navigate("/payment");
        });
      setIsLoading(true);
    },
    onClose: (err) => {
      toast.error("Payment was canceled or encountered an error", {
        autoClose: 1000,
      });
    },
    onError: (error) => {
      toast.error(`Payment Error: ${error.message}`, { autoClose: 1000 });
    },
  };

  const handlePayment = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputEmail);

    setIsValid(isValidEmail);
  };

  return (
    <>
      <ToastContainer />
      <div className="container ">
        <div className="nav-container">
          <div className="nav">
            <div className="nav-image">
              <div className="logo">
                <Link to="/">
                  <img src={logo} style={{ height: "80px" }} alt="logo" />
                </Link>
              </div>
            </div>

            {/* <div className="nav-download"> <a href="/instruction" style={{textDecoration:'none',color:"white"}}>How to generate vnin</a> </div> */}
          </div>
        </div>

        <div class="marquee">
          <div>
            Caution!!! This service is for the purpose of Newspaper publication
            only and does not in any way seek to modify, update or alter your
            already existing information of your NIN in the National identity
            Management Commision(NIMC) database
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {" "}
          <span className="form-box-asterix">
            Please ensure that the payment verification information is filled
            out correctly.
          </span>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="payment-boxes">
              <div className="payment-box">
                <h3>Old Name</h3>

                <h5 style={{ marginTop: "0.5rem" }}>{name}</h5>

                <h3 style={{ marginTop: "0.5rem" }}>New Name:</h3>
                <h5 style={{ marginTop: "0.5rem" }}>{newName} </h5>
                {/* <h3>{email}</h3> */}

                <h3 style={{ marginTop: "0.5rem" }}>Publication Type:</h3>
                <h5 style={{ marginTop: "0.5rem" }}>Classified</h5>

                <h3 style={{ marginTop: "0.5rem" }}>Price: N{publication}</h3>
                {/* <h3 style={{ marginTop: "0.5rem" }}>Vat: - N700</h3> */}
                <h3
                  style={{
                    marginTop: "0.5rem",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                    padding: "0.5rem auto",
                  }}
                >
                  Total: {totalAmount}
                </h3>
              </div>
              <div className="checkout-form">
                <form>
                  <div className="checkout-field">
                    <label>Name</label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Enter your name"
                      onChange={(e) => setPaystackName(e.target.value)}
                    />
                  </div>
                  <div className="checkout-field">
                    <label>Email</label>
                    <input
                      type="text"
                      id="email"
                      placeholder="Enter your email"
                      onChange={handlePayment}
                    />
                    {isValid ? (
                      <p style={{ color: "green" }}>Valid email address</p>
                    ) : (
                      <p style={{ color: "red" }}>Invalid email address</p>
                    )}
                  </div>
                  <div className="checkout-field">
                    <label>Phone</label>
                    <input
                      type="text"
                      id="phone"
                      placeholder="Enter your phoneNo"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </form>
                <PaystackButton
                  className="paystack-button"
                  {...componentProps}
                />
              </div>
            </div>
          </>
        )}
        <div className="footer-container">
          <div className="footer">
            <div className="footer-header">
              <h2>
                {" "}
                &copy; 2023 <br /> The Classified Newspaper{" "}
              </h2>
            </div>
            <div className="footer-content">
              <Link to="/terms">Terms and Condition</Link>
              <a href="#">About Us</a>
              <Link to="/privacy">Privacy Policy</Link>
              <div className="nav-download1">
                <a href="">Contact us</a>
                <p>09090909137 </p>
                <p>09090909136 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Payment;
